import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/Layout'
import Typography from '~components/Typography'
import Quote from '~components/Quote'
import QuoteAuthor from '~components/QuoteAuthor'
import theme from '~styles/theme'
import { NAVIGATION } from '~utils/constants'

import messages from './messages'
import Content from './Content'
import Header from './Header'
import QuoteText from './QuoteText'
import Benefits from './Benefits'
import Services from './Services'

const metaTags = {
  title: 'Personal Training und Coching in Zürich',
  description:
    'Trainingsplan persönlich auf dich zugeschnitten. Ausdauer, Kraft, funktional Training, Beweglichkeit, Stabilität, Abnehmen.',
  slug: NAVIGATION.SERVICE.PERSONAL_TRAINING,
  image: {
    url: 'PageServicePersonalTraining/HeroServicePersonalTraining_01--large',
    alt: 'Tania erklärt einem Kunden eine Übung mit dem Trainingsband',
  },
}

const hero = {
  pictureData: {
    path: 'PageServicePersonalTraining',
    alt: 'Tania erklärt einem Kunden eine Übung mit dem Trainingsband',
    fallback: {
      name: 'HeroServicePersonalTraining_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE1IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1ZjhlNjMiIGQ9Ik0wIDBoNjAxNXYyNTYxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43IDExLjcpIHNjYWxlKDIzLjQ5NjEpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZmZmOWZmIiBjeD0iMjkiIGN5PSI2NCIgcng9IjIzIiByeT0iMzciLz48ZWxsaXBzZSBmaWxsPSIjMDA4N2ZmIiBjeD0iMTYzIiBjeT0iNzgiIHJ4PSIzMCIgcnk9IjM1Ii8+PGVsbGlwc2UgZmlsbD0iIzAwMWQwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgyMi4zMDI0NCAtMS41MDYxIDQuMTk0NiA2Mi4xMTM2OSA3OC4xIDk3KSIvPjxlbGxpcHNlIGZpbGw9IiNiNmZmYWUiIGN4PSIyMjAiIGN5PSI1MCIgcng9IjE1IiByeT0iNDgiLz48ZWxsaXBzZSBmaWxsPSIjNDE5MDEyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC04Ni40IDY2LjIgLTUzLjgpIHNjYWxlKDMzLjIwNDQgODcuMDE0MjMpIi8+PGVsbGlwc2UgY3g9IjciIGN5PSIxMDYiIHJ4PSI0MSIgcnk9IjkiLz48cGF0aCBmaWxsPSIjZmZmY2ZmIiBkPSJNLTE2IDM3bDczLTMtMjUgMzF6Ii8+PGVsbGlwc2UgZmlsbD0iIzU0YjlmZiIgY3g9IjE1NiIgY3k9IjYzIiByeD0iMjMiIHJ5PSIyMyIvPjwvZz48L3N2Zz4=',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroServicePersonalTraining_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MzQ4IDQwMTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1NjgzNTUiIGQ9Ik0wIDBoNTM0OHY0MDExSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC40IDEwLjQpIHNjYWxlKDIwLjg5MDYzKSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iI2ZmZjNmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSg4NiAtMzUuMyA2NS42KSBzY2FsZSg0NS41ODI0NyAyNy45MzA4MykiLz48ZWxsaXBzZSBmaWxsPSIjZjJmZmU5IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDYwLjYxMDkzIDEwMC45NDI1NCAtMjQuNTk1OTYgMTQuNzY4NjQgMjU1IDQ1KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDAwMDEiIGN4PSIxOTEiIGN5PSIxNjgiIHJ4PSI4MCIgcnk9IjI0Ii8+PGVsbGlwc2UgZmlsbD0iIzFiOTVmZiIgY3g9IjE3NyIgY3k9IjExOSIgcng9IjQzIiByeT0iNDMiLz48ZWxsaXBzZSBmaWxsPSIjMTkwMjAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC03Mi4wNjE2MyAyOS4zNjIyMiAtMTEuMTcxOTcgLTI3LjQxODU5IDUxLjQgMTY3LjYpIi8+PGVsbGlwc2UgZmlsbD0iIzBkNDUwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjguOTI1ODQgOC4wNzYyNCAtNy43NTMwMiAtMjcuNzY4MiA4Mi40IDEwNCkiLz48cGF0aCBmaWxsPSIjOWZmNzUyIiBkPSJNNzAgNDJsMTE0LTIxTDY5LTE2eiIvPjxlbGxpcHNlIGZpbGw9IiMwMDAwMDIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMzguMiAtMjIxLjggMTE4LjMpIHNjYWxlKDMzLjI0NjM5IDE4LjY0MjY0KSIvPjwvZz48L3N2Zz4=',
      },
      breakpointMedium: {
        name: 'HeroServicePersonalTraining_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDQwMTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1ODgyNTYiIGQ9Ik0wIDBoNjAxNnYzOTk1SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIGN4PSIzMCIgY3k9Ijg5IiByeD0iMjUiIHJ5PSIzNyIvPjxlbGxpcHNlIGZpbGw9IiMwYzk1ZmYiIGN4PSIxNjIiIGN5PSIxMDEiIHJ4PSIzMiIgcnk9IjM0Ii8+PGVsbGlwc2UgZmlsbD0iIzBkMDYwNiIgY3g9IjEwMiIgY3k9IjE0OCIgcng9IjE0MiIgcnk9IjI3Ii8+PHBhdGggZmlsbD0iI2RkZmZkNyIgZD0iTTIzNiAxMjdMMjI4LThsLTU2LTh6Ii8+PGVsbGlwc2UgZmlsbD0iIzE0NDMwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjQuODU2MTQgLTMuMjg0OTEgNC43NTY0MyAtMzUuOTkwNzUgNzAuNyA5NS4yKSIvPjxlbGxpcHNlIGZpbGw9IiM5MGVlNDYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNC4zMjE3NiAyNC42NjQwNiAtNDAuOTIwMDUgNy4xNzAyMSA5NyAxNS41KSIvPjxwYXRoIGZpbGw9IiNmZmU2YWMiIGQ9Ik03MiAxNjFoNTZ2OUg3MnoiLz48ZWxsaXBzZSBjeD0iMTU4IiBjeT0iMTYwIiByeD0iMzEiIHJ5PSIxOCIvPjwvZz48L3N2Zz4=',
      },
      breakpointLarge: {
        name: 'HeroServicePersonalTraining_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE1IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1ZjhlNjMiIGQ9Ik0wIDBoNjAxNXYyNTYxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43IDExLjcpIHNjYWxlKDIzLjQ5NjEpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZmZmOWZmIiBjeD0iMjkiIGN5PSI2NCIgcng9IjIzIiByeT0iMzciLz48ZWxsaXBzZSBmaWxsPSIjMDA4N2ZmIiBjeD0iMTYzIiBjeT0iNzgiIHJ4PSIzMCIgcnk9IjM1Ii8+PGVsbGlwc2UgZmlsbD0iIzAwMWQwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgyMi4zMDI0NCAtMS41MDYxIDQuMTk0NiA2Mi4xMTM2OSA3OC4xIDk3KSIvPjxlbGxpcHNlIGZpbGw9IiNiNmZmYWUiIGN4PSIyMjAiIGN5PSI1MCIgcng9IjE1IiByeT0iNDgiLz48ZWxsaXBzZSBmaWxsPSIjNDE5MDEyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC04Ni40IDY2LjIgLTUzLjgpIHNjYWxlKDMzLjIwNDQgODcuMDE0MjMpIi8+PGVsbGlwc2UgY3g9IjciIGN5PSIxMDYiIHJ4PSI0MSIgcnk9IjkiLz48cGF0aCBmaWxsPSIjZmZmY2ZmIiBkPSJNLTE2IDM3bDczLTMtMjUgMzF6Ii8+PGVsbGlwc2UgZmlsbD0iIzU0YjlmZiIgY3g9IjE1NiIgY3k9IjYzIiByeD0iMjMiIHJ5PSIyMyIvPjwvZz48L3N2Zz4=',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const PageServicePersonalTraining = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <Header as="h2" type="header2" limit align="center" uppercase>
        {messages.title1}
      </Header>
      <Typography limit>{messages.paragraph1}</Typography>
      <Quote
        width="65%"
        position="end"
        background={theme.palette.secondary.main}
      >
        <QuoteText position="right">{messages.quote1}</QuoteText>
      </Quote>
      <Typography limit align="center" as="h3" type="header3">
        {messages.title2}
      </Typography>
      <Typography limit>{messages.paragraph2}</Typography>
      <Benefits />
      <Typography limit>{messages.paragraph3}</Typography>
      <Services />
      <QuoteAuthor>
        <QuoteAuthor.Quote align="center">{messages.quote2}</QuoteAuthor.Quote>
        <QuoteAuthor.Author align="center">
          {messages.author2}
        </QuoteAuthor.Author>
      </QuoteAuthor>
    </Content>
  </Layout>
)

PageServicePersonalTraining.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageServicePersonalTraining
