/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconSpineTwist = () => (
  <Svg viewBox="0 0 419.259 419.259">
    <path d="M77.61 304.54v-26.16a9 9 0 1 1 18 0v17.16H273.8l-16.43-86.2a9 9 0 1 1 17.68-3.37l18.46 96.89a9 9 0 0 1-8.84 10.68H86.61a9 9 0 0 1-9-9zm136-197.14a41.24 41.24 0 0 1 41.2-41.2 41.24 41.24 0 0 1 41.2 41.2 41.24 41.24 0 0 1-41.2 41.2 41.25 41.25 0 0 1-41.2-41.2zm18 0c0 12.79 10.41 23.2 23.2 23.2 12.8 0 23.2-10.41 23.2-23.2 0-12.8-10.4-23.2-23.2-23.2-12.79 0-23.2 10.4-23.2 23.2zm178.65 227.66H9a9 9 0 1 0 0 18h401.26a9 9 0 1 0 0-18zM123.5 178.29a9 9 0 0 0 7.52 4.06h249.84a9 9 0 0 0 7.52-4.06l14.38-21.87a9 9 0 0 0-15.04-9.9l-11.71 17.83H135.87l-11.72-17.82a9 9 0 0 0-15.03 9.89l14.38 21.87z" />
  </Svg>
)

export default IconSpineTwist
