import React, { Component, Fragment } from 'react'

import messages from './messages'
import Service from './Service'
import ServiceWrapper from './ServiceWrapper'
import ServiceOverlay from './ServiceOverlay'
import ServiceBack from './ServiceBack'
import ServiceAnimate from './ServiceAnimate'
import ServiceHover from './ServiceHover'
import Wrapper from './Wrapper'
import IconBike from './Icons/IconBike'
import IconFlower from './Icons/IconFlower'
import IconRollerSkate from './Icons/IconRollerSkate'
import IconScale from './Icons/IconScale'
import IconSpineTwist from './Icons/IconSpineTwist'
import IconStopwatch from './Icons/IconStopwatch'
import IconWeighingScale from './Icons/IconWeighingScale'
import IconWeight from './Icons/IconWeight'

const services = [
  {
    textFront: messages.service1,
    textBack: messages.service1Description,
    Icon: IconWeight,
  },
  {
    textFront: messages.service2,
    textBack: messages.service2Description,
    Icon: IconBike,
  },
  {
    textFront: messages.service3,
    textBack: messages.service3Description,
    Icon: IconFlower,
  },
  {
    textFront: messages.service4,
    textBack: messages.service4Description,
    Icon: IconStopwatch,
  },
  {
    textFront: messages.service5,
    textBack: messages.service5Description,
    Icon: IconWeighingScale,
  },
  {
    textFront: messages.service6,
    textBack: messages.service6Description,
    Icon: IconRollerSkate,
  },
  {
    textFront: messages.service7,
    textBack: messages.service7Description,
    Icon: IconScale,
  },
  {
    textFront: messages.service8,
    textBack: messages.service8Description,
    Icon: IconSpineTwist,
  },
]

class Services extends Component {
  state = {
    activeServiceId: null,
  }

  setActiveServiceId = (activeServiceId) =>
    this.setState((prevState) =>
      prevState.activeServiceId === activeServiceId
        ? { activeServiceId: null }
        : { activeServiceId },
    )

  render() {
    const { activeServiceId } = this.state
    return (
      <Wrapper onMouseLeave={() => this.setActiveServiceId(null)}>
        {services.map(({ textFront, textBack, Icon }, index) => (
          <Fragment key={textFront}>
            <ServiceHover
              position={index}
              // TODO find better solution (too many actions are fired, especially on mobile)
              // not possible to deselect box on mobile by re-clicking on it
              // try CSS onHover
              onFocus={() => this.setActiveServiceId(index)}
              onTouchEnd={() => this.setActiveServiceId(index)}
              onMouseDown={() => this.setActiveServiceId(index)}
              onMouseEnter={() => this.setActiveServiceId(index)}
            />
            <ServiceWrapper position={index}>
              <ServiceAnimate isActive={activeServiceId === index}>
                <Icon />
                <Service as="h4" type="header5">
                  {textFront}
                </Service>
              </ServiceAnimate>
            </ServiceWrapper>
            <ServiceOverlay
              position={index}
              isActive={activeServiceId === index}
            >
              <ServiceBack preWrap type="body4">
                {textBack}
              </ServiceBack>
            </ServiceOverlay>
          </Fragment>
        ))}
      </Wrapper>
    )
  }
}

Services.propTypes = {}

export default Services
