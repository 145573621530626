/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconCheckmark = () => (
  <Svg viewBox="0 0 129 129">
    <path d="M40.5 61.1a4.1 4.1 0 0 0-5.8 5.8l18.9 18.9a4 4 0 0 0 2.9 1.2h.2c1.1-.1 2.2-.6 3-1.5L107 28.8c1.4-1.7 1.2-4.3-.5-5.8a4.22 4.22 0 0 0-5.8.5L56.2 76.8 40.5 61.1z" />
    <path d="M95.1 15.3c-23-14.4-52.5-11-71.7 8.2C.8 46.1.8 83 23.4 105.6a57.94 57.94 0 0 0 82 0 57.7 57.7 0 0 0 8.1-71.9 4.06 4.06 0 1 0-6.9 4.3 49.53 49.53 0 0 1-7 61.8 49.97 49.97 0 0 1-70.5 0 49.97 49.97 0 0 1 0-70.5 49.8 49.8 0 0 1 61.7-7.1 4.06 4.06 0 1 0 4.3-6.9z" />
  </Svg>
)

export default IconCheckmark
