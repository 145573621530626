import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { transitions } from '~styles/constants'

const ServiceOverlay = styled.div`
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transform: ${({ isActive }) => (isActive ? 'scale(1)' : 'scale(1.1)')};
  transition: opacity ${transitions.duration.standard}ms
      ${transitions.easing.sharp},
    transform ${transitions.duration.standard}ms ${transitions.easing.sharp};
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: ${({ position }) => `service-${position}`};
  background: ${({ theme }) => theme.palette.common.white};
  ${media.mq1`
    padding: ${({ theme }) => theme.spacing[12]};
    margin: ${({ theme }) => theme.spacing[4]};
  `};
  ${media.mq234`
    padding: ${({ theme }) => theme.spacing[24]};
    margin: ${({ theme }) => theme.spacing[12]};
  `};
`

export default ServiceOverlay
