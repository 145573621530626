import styled from 'styled-components'

import Typography from '~components/Typography'

const Header = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  text-align: center;
  grid-area: header;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`

export default Header
