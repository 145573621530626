import styled from 'styled-components'

import { maxWidthStyle } from '~components/MaxWidthWrapper'
import Trapezoid, { Triangle, trianglePosition } from '~components/Trapezoid'

const StyledTrapezoid = styled(Trapezoid)`
  z-index: 1;
  background: ${(props) => props.background};
  width: ${({ width }) => width || '100%'};
  justify-self: ${(props) => props.position};
  ${maxWidthStyle};
  grid-area: trapezoid;
  margin: 0;

  & > ${Triangle} {
    fill: ${(props) => props.background};
    width: 3vw;
    ${({ position }) =>
      position === 'start'
        ? trianglePosition.right.bottom
        : trianglePosition.left.top};
  }
`

export default StyledTrapezoid
