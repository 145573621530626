import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { maxWidthStyle } from '~components/MaxWidthWrapper'

const Wrapper = styled.section`
  ${maxWidthStyle};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing[1]}
    ${({ theme }) => theme.spacing[1]};
  text-align: center;
  ${media.mq12`
    grid-template-columns: repeat(2, calc(50% - 1px));
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      'service-0 service-1'
      'service-2 service-3'
      'service-4 service-5'
      'service-6 service-7';
  `};
  ${media.mq34`
    grid-template-columns: repeat(4, calc(25% - 1px));
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'service-0 service-1 service-2 service-3'
      'service-4 service-5 service-6 service-7';
  `};
`

export default Wrapper
