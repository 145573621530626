/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconRollerSkate = () => (
  <Svg viewBox="0 0 512 512">
    <path d="M489.2 361.71c0-62.41-49.26-113.54-110.95-116.54L260.9 143.75V55.5a55.15 55.15 0 0 0-16.26-39.26 55.65 55.65 0 0 0-58.8-12.7L40.24 58.66v142.11L22.8 302.51v86.33h46.33a76 76 0 0 0-14.73 59.88C60.9 485.82 93.28 512 129.7 512c4.38 0 8.81-.38 13.27-1.16a76 76 0 0 0 49.4-31.42 76 76 0 0 0 12.72-57.16 76 76 0 0 0-14.76-33.42h114.2a76 76 0 0 0-14.72 59.87c6.5 37.1 38.87 63.28 75.3 63.28 4.38 0 8.82-.38 13.27-1.16a76 76 0 0 0 49.4-31.42 76 76 0 0 0 12.72-57.16 76.12 76.12 0 0 0-14.68-33.41h63.37V361.7zM167.82 462.2a46.2 46.2 0 0 1-30.03 19.1 46.56 46.56 0 0 1-53.84-37.76A46.55 46.55 0 0 1 129.77 389a46.58 46.58 0 0 1 45.78 38.46 46.2 46.2 0 0 1-7.73 34.74zM52.8 358.84v-40.05h83.74l40.05 40.05H52.8zm350.43 103.34a46.2 46.2 0 0 1-30.03 19.1 46.56 46.56 0 0 1-53.85-37.76 46.55 46.55 0 0 1 45.9-54.55c9.47 0 18.7 2.9 26.6 8.44a46.2 46.2 0 0 1 19.1 30.03 46.2 46.2 0 0 1-7.72 34.74zM219 358.83h.01l-70.03-70.04H55.59l12.2-71.13h38.87v-30H70.24V79.38L196.42 31.6a25.57 25.57 0 0 1 27 5.85 25.35 25.35 0 0 1 7.48 18.04v95.18l-33.1 33.1 21.2 21.21 31-31 18.32 15.84-32.23 32.24 21.2 21.21 33.8-33.78 18.32 15.83-35.03 35.03 21.21 21.21L332.17 245l34.75 30.03h5.59c46.83 0 85.11 37.33 86.64 83.8H219z" />
    <path d="M175.58 80.98h30v30h-30zM114.75 420.49v30h30v-30zM350.16 420.48v30h30v-30z" />
  </Svg>
)

export default IconRollerSkate
