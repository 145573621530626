/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconScale = () => (
  <Svg viewBox="0 0 512 512">
    <path d="M358.4 177c-2.28-54.52-47.33-98.17-102.4-98.17-55.07 0-100.12 43.65-102.4 98.17h-46.93v30h298.66v-30H358.4zm-87.4 0v-38.33h-30V177h-57.36c2.25-37.97 33.84-68.17 72.36-68.17 38.52 0 70.1 30.2 72.36 68.17H271zM106.67 283.67h30v153.67h-30zM177 283.67h30v153.67h-30zM375.33 283.67h30v153.67h-30zM305 283.67h30v153.67h-30z" />
    <path d="M390.8 70.33a165.49 165.49 0 0 0-53.88-49.05A164.36 164.36 0 0 0 256 0c-28.38 0-56.36 7.36-80.92 21.28a165.49 165.49 0 0 0-53.88 49.05H17V512h478V70.33H390.8zM465 482H47V100.33h90.58l4.4-7.05C166.74 53.66 209.36 30 256 30s89.26 23.66 114.02 63.28l4.4 7.05H465V482z" />
  </Svg>
)

export default IconScale
