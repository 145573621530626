/**
 *
 * IconBike
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconBike = () => (
  <Svg viewBox="0 0 512 512">
    <path d="M511.98 316.86c-.58-30.49-13-58.93-34.98-80.08a113.3 113.3 0 0 0-81.35-31.89c-7.86.15-15.57 1.1-23.07 2.78l-42.04-98.88H394v-30h-86.14l-13.8 20.87 22.62 53.22h-131.6l-10.07-22.75h37.83v-30h-60.88l-13.71 21.07 18.7 42.21-18.36 44.07a115.35 115.35 0 0 0-22.24-2.58A113.42 113.42 0 0 0 35 236.79 113.41 113.41 0 0 0 .02 316.86C-1.18 379.8 49.04 431.98 112 433.19l2.2.02c59.08 0 108.18-45.28 113.64-103.42H252v-30h-25.27a113.35 113.35 0 0 0-30.3-59.92 114.36 114.36 0 0 0-29.59-22.18l14.5-34.82h148.1l14.97 35.21a114.4 114.4 0 0 0-28.83 21.79 113.42 113.42 0 0 0-31.9 81.35c1.2 62.2 52.17 111.99 114.12 111.99l2.21-.02c62.94-1.2 113.17-53.4 111.97-116.33zm-314.35 12.93c-5.31 41.5-40.85 73.42-83.44 73.42-.54 0-1.09 0-1.63-.02-46.4-.88-83.43-39.36-82.55-85.76A83.62 83.62 0 0 1 55.8 258.4a83.59 83.59 0 0 1 59.97-23.51c3.72.07 7.4.4 11.02.95l-30.47 73.19 13.85 20.76h87.46zm-22.82-69.12a83.64 83.64 0 0 1 21.32 39.12h-63.47l22.59-54.25c7.13 4 13.72 9.07 19.56 15.13zm283.67 116.74a83.62 83.62 0 0 1-60.67 25.8c-45.67 0-83.25-36.7-84.13-82.56a83.62 83.62 0 0 1 23.51-59.98 84.47 84.47 0 0 1 19.03-14.82l22.94 53.94H356v30h45.83l13.8-20.87-31.03-73a85.36 85.36 0 0 1 13.28-1.05 83.6 83.6 0 0 1 58.32 23.53A83.62 83.62 0 0 1 482 317.43a83.62 83.62 0 0 1-23.51 59.98z" />
  </Svg>
)

export default IconBike
