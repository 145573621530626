import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { shadowsArray, limitMaxWidth } from '~styles/constants'

const Wrapper = styled.section`
  display: grid;
  grid-template-areas: ${({ position }) =>
    position === 'start'
      ? "'background trapezoid space'"
      : "'space trapezoid background'"};
  filter: drop-shadow(${shadowsArray[0][0]}) drop-shadow(${shadowsArray[0][1]});
  ${media.mq1`
    grid-template-columns: 1fr ${limitMaxWidth.mq1} 1fr;
  `};
  ${media.mq2`
    grid-template-columns: 1fr ${limitMaxWidth.mq2} 1fr;
  `};
  ${media.mq3`
    grid-template-columns: 1fr ${limitMaxWidth.mq3} 1fr;
  `};
  ${media.mq4`
    grid-template-columns: 1fr ${limitMaxWidth.mq4} 1fr;
  `};
`

export default Wrapper
