/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconWeight = () => (
  <Svg viewBox="0 0 511.999 511.999">
    <path d="M431 172.3a108.6 108.6 0 0 0 21.33-64.4c0-59.5-48.4-107.9-107.9-107.9H167.57c-59.5 0-107.9 48.4-107.9 107.9A108.6 108.6 0 0 0 81 172.3l34.16 45.91c-31.68 34.93-48.98 79.64-48.98 127.28 0 33.94 9.07 67.25 26.23 96.34a190.85 190.85 0 0 0 68.82 68.16l3.48 2h182.57l3.48-2a190.85 190.85 0 0 0 68.82-68.16 189.65 189.65 0 0 0 26.24-96.34c0-47.64-17.3-92.35-48.99-127.28l34.16-45.9zM415.81 345.5c0 28.58-7.63 56.62-22.07 81.09a160.83 160.83 0 0 1-54.6 55.42h-166.3a160.82 160.82 0 0 1-54.6-55.42 159.64 159.64 0 0 1-22.07-81.1 158.5 158.5 0 0 1 49.04-115.18l9.52-9.16-49.67-66.74a78.4 78.4 0 0 1-15.4-46.5c0-42.96 34.94-77.9 77.9-77.9h176.87c42.95 0 77.9 34.94 77.9 77.9a78.41 78.41 0 0 1-15.41 46.5l-49.67 66.74 9.52 9.16a158.5 158.5 0 0 1 49.04 115.19z" />
    <path d="M353.7 80H158.3l-18.56 36.95 56.93 79.69 10.43-3.35a159.75 159.75 0 0 1 48.89-7.62c16.68 0 33.13 2.56 48.9 7.62l10.42 3.35 56.93-79.7L353.7 80zm-50.26 81.66a190 190 0 0 0-47.44-6 190 190 0 0 0-47.44 6l-33.88-47.42 2.13-4.24H335.2l2.13 4.24-33.88 47.42zM219.78 333l38.92-42.82h-35.73L191 325.48v-35.3h-32.25v97.3H191v-24.04l7.37-8.2 24.32 32.25h37.95z" />
    <path d="M325.97 360.8a30.55 30.55 0 0 1-11.95 2.36c-14.04 0-23.22-9.73-23.22-24.33 0-15 9.18-24.32 23.63-24.32 8.62 0 15.71 3.61 21.83 10.7l20.99-18.49c-9.87-12.1-25.3-18.76-44.48-18.76-32.11 0-55.05 20.99-55.05 50.87 0 29.89 22.94 50.88 54.35 50.88 14.32 0 30.58-4.3 42.4-12.37v-41.15h-28.5v24.6z" />
  </Svg>
)

export default IconWeight
