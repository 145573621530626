const messages = {
  header:
    'Fitness vor der Haustür. Ich trainiere dich bei dir zuhause, im Büro oder draussen.\n',
  subHeader: 'Deine Vorteile',
  benefitsAlt: 'Zwei Hanteln a je 8 Kilo liegen auf dem Boden',
  benefit1: 'Individueller Trainingsplan',
  benefit2: 'Persönliche Betreuung',
  benefit3: 'Optimale Übungskorrektur',
  benefit4: 'Maximale Effektivität und Effizienz',
  benefit5: 'Langfristiger Erfolg und hohe Motivation',
  benefit6: 'Auf deine Wünsche zugeschnitten',
}

export default messages
