import styled from 'styled-components'

import { transitions } from '~styles/constants'

const ServiceAnimate = styled.div`
  opacity: ${({ isActive }) => (isActive ? '0' : '1')};
  transform: ${({ isActive }) => (isActive ? 'scale(0.8)' : 'scale(1)')};
  transition: opacity ${transitions.duration.short}ms
      ${transitions.easing.sharp},
    transform ${transitions.duration.short}ms ${transitions.easing.sharp};
`

export default ServiceAnimate
