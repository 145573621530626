import styled from 'styled-components'
import { rgba } from 'polished'

import { media } from '~styles/mediaQuery'
import { fluidSpacingFunc } from '~styles/helpers'

const Wrapper = styled.section`
  ${fluidSpacingFunc([{ property: 'padding', values: [8, 4] }])};
  position: relative;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacing[24]};
  justify-content: center;
  background: ${({ theme }) => rgba(theme.palette.primary.main, 0.47)};
  ${media.mq1`
    grid-template-columns: 1fr minmax(min-content, max-content) 1fr;
    grid-template-areas:
    'header header header'
    '. benefit-0 .'
    '. benefit-1 .'
    '. benefit-2 .'
    '. benefit-3 .'
    '. benefit-4 .'
    '. benefit-5 .';
  `};
  ${media.mq234`
    grid-column-gap: ${({ theme }) => theme.spacing[32]};
    grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-areas: 
    'header header'
    'benefit-0 benefit-1'
    'benefit-2 benefit-3'
    'benefit-4 benefit-5';
  `};
`

export default Wrapper
