import React from 'react'

import messages from './messages'
import Header from './Header'
import Benefit from './Benefit'
import BenefitWrapper from './BenefitWrapper'
import Wrapper from './Wrapper'
import Img from './Img'
import IconCheckmark from './Icons/IconCheckmark'

const benefits = [
  messages.benefit1,
  messages.benefit2,
  messages.benefit3,
  messages.benefit4,
  messages.benefit5,
  messages.benefit6,
]

const benefitsImage = {
  alt: messages.benefitsAlt,
  path: 'PageServicePersonalTraining',
  name: 'Weights_01',
  placeholder:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDM5IDE2MzIiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1ODY2NzgiIGQ9Ik0wIDBoMjQzOXYxNjI5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjggNC44KSBzY2FsZSg5LjUyNzM0KSIgZmlsbC1vcGFjaXR5PSIuNSI+PHBhdGggZmlsbD0iIzAxMmIzYyIgZD0iTTE2NC4zIDE2Mi41bC0zMi40LTE1LjggMzMuOC02OS4yIDMyLjQgMTUuOHoiLz48ZWxsaXBzZSBmaWxsPSIjN2I4NDk5IiBjeD0iMTUiIGN5PSIzOSIgcng9Ijc1IiByeT0iNzUiLz48ZWxsaXBzZSBmaWxsPSIjMDkyZjQwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC03Ljk1NjQ0IDEwLjYzNjI0IC0yNi4zMjk2NyAtMTkuNjk1OTIgMTE0LjQgNjMuOSkiLz48ZWxsaXBzZSBmaWxsPSIjMGEzMTQxIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDQxLjggLTk4LjUgMTYyLjcpIHNjYWxlKDIzLjczNzk4IDE3LjMzNTg5KSIvPjxwYXRoIGZpbGw9IiM2ZTc2OGEiIGQ9Ik0yNTYgNjlsLTMzLTg0TC0xMiAyeiIvPjxlbGxpcHNlIGZpbGw9IiMwNzJmNDIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTAuNTU2MSAtMTQuNjA4MDIgNi42MTI1OCA0Ljc3ODQgMTA1LjEgNDcuNCkiLz48cGF0aCBmaWxsPSIjNzQ4MTkyIiBkPSJNMTUyLjggMTA2bDQuNyAxOC44TDEyOSAxMjNsLTMuMy0yNC45eiIvPjxlbGxpcHNlIGZpbGw9IiM2OTcyODUiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTYuMzI4NyAtOTQuNzA0MjggMzQuMjUxMTcgNS45MDU1MSAyMC4zIDEzNS44KSIvPjwvZz48L3N2Zz4=',
}

const Benefits = () => (
  <Wrapper>
    <Header as="h3" type="header3" preWrap>
      {messages.header}
      <Header as="span" type="header3" uppercase>
        {messages.subHeader}:
      </Header>
    </Header>
    {benefits.map((benefit, index) => (
      <BenefitWrapper position={index} key={benefit}>
        <IconCheckmark />
        <Benefit as="h4" type="header4">
          {benefit}
        </Benefit>
      </BenefitWrapper>
    ))}
    <Img imageData={benefitsImage} fit="cover" />
  </Wrapper>
)

Benefits.propTypes = {}

export default Benefits
