const messages = {
  header: 'Personal Training / Online Coaching',
  title1:
    'Personal Training ist der optimale Ausgleich zu deinem Alltag und die ideale Lösung um schnell und effektiv dein Ziel zu erreichen',
  title2: 'Eine persönliche Trainerin, die dich kennt',
  paragraph1:
    'Egal ob dein Ziel abzunehmen ist, den Körper zu formen, am ersten Lauf teilzunehmen oder nach einer Schwangerschaft wieder fit zu werden, ich helfe dir dabei. Du erhältst professionelle Unterstützung bei der Optimierung deiner Fitness und Gesundheit.',
  paragraph2:
    'In einem Vorgespräch wird eine Bedarfsanalyse darüber erstellt, was früher schon trainiert wurde, Verletzungen und Einschränkungen miteinbezogen. Gemeinsam setzen wir deine Ziele und stimmen dein Programm exakt auf diese ab. Wir gestalten dein Training, deine Ernährung und deine Regeneration möglichst effektiv, damit du den maximalen Erfolg erreichen kannst.',
  paragraph3:
    'Jeder hat seine Gründe zu trainieren. Aber die meisten Menschen haben den Wunsch, eine etwas bessere Version von sich selbst zu sein.',
  paragraph4:
    'Wenn du wirklich das Beste aus dir herausholen und Resultate sehen willst, dann ist das Personal Training genau das richtige für dich. Verschwende deine Zeit nicht mehr und lass keine Ausreden mehr gelten.',
  quote1:
    'Dein Körper kann alles schaffen. Es ist dein Geist, den du überzeugen musst.',
  quote2: 'Niemand, der sein Bestes gegeben hat, hat es später bereut.',
  author2: 'George Halas',
}

export default messages
