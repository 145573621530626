import React from 'react'
import PropTypes from 'prop-types'

import StyledTrapezoid from './StyledTrapezoid'
import Wrapper from './Wrapper'
import Background from './Background'

const Quote = ({ children, position, width, background }) => (
  <Wrapper position={position}>
    <StyledTrapezoid position={position} width={width} background={background}>
      {children}
    </StyledTrapezoid>
    <Background background={background} />
  </Wrapper>
)

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
  width: PropTypes.string,
  background: PropTypes.string.isRequired,
}

export default Quote
