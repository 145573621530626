/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconStopWatch = () => (
  <Svg viewBox="0 0 512 512">
    <path d="M409.91 140.42a223.12 223.12 0 0 0-4.72-4.57l18.77-24.13 12.16 9.45 18.42-23.68-48-37.33-18.42 23.68 12.16 9.46-18.27 23.5a215.16 215.16 0 0 0-68.34-32.45V30h17V0H181.33v30h17v54.35A215.17 215.17 0 0 0 130 116.8l-18.27-23.5 12.16-9.46-18.42-23.68-48 37.33 18.42 23.68 12.16-9.45 18.77 24.13a216.24 216.24 0 0 0-68.47 158.48c0 58.15 22.63 112.8 63.75 153.92A216.24 216.24 0 0 0 255.99 512c58.15 0 112.81-22.64 153.92-63.75s63.76-95.78 63.76-153.92c0-58.14-22.64-112.8-63.76-153.91zM228.33 30h55.34v48.4a221.1 221.1 0 0 0-55.34 0V30zM271 481.4V450h-30v31.4c-91.54-7.27-164.8-80.52-172.07-172.07h31.4v-30h-31.4C76.6 182.84 157.57 106.67 256 106.67s179.4 76.17 187.07 172.66h-31.4v30h31.4c-7.28 91.55-80.53 164.8-172.07 172.07z" />
    <path d="M270.39 155.75H241.6c-5.79 19.64-34.7 118.6-34.7 138.58 0 27.07 22.03 49.09 49.09 49.09s49.08-22.02 49.08-49.09c0-19.97-28.9-118.94-34.7-138.57zm-14.4 157.67a19.1 19.1 0 0 1-19.07-19.09c0-7.32 8.39-41 19.08-79.72 10.7 38.72 19.08 72.4 19.08 79.72A19.1 19.1 0 0 1 256 313.42z" />
  </Svg>
)

export default IconStopWatch
