const messages = {
  service1: 'Krafttraining Funktionelles Training',
  service1Description:
    'Mehr Kraft\n\nKörper straffen & formen\n\nMuskelaufbau\n\nKoordination zwischen den Muskeln',
  service2: 'Ausdauertraining',
  service2Description:
    'Verbesserung der Ausdauer\n\npositive Auswirkungen auf das Herz-Kreislauf-System\n\nstärkt das Immunsystem',
  service3: 'Beweglichkeitstraining',
  service3Description: 'mehr Leistung\n\nVerletzungsprophylaxe',
  service4: 'Hochintensives Intervalltraining (HIIT)',
  service4Description:
    'Fettverbrennung\n\nsteigert den Stoffwechsel\n\nherausfordernd',
  service5: 'Stabilisationstraining',
  service5Description:
    'Körperspannung und -haltung verbessern\n\nbeugt Verletzungen vor',
  service6: 'Sportspezifisches Training',
  service6Description:
    'Trainingspläne\n\nÜbungen für individuelle sportliche Ziele',
  service7: 'Gewichtsreduktion',
  service7Description:
    'Stoffwechsel optmieren\n\nMuskelmasse steigern\n\nFettmasse reduzieren',
  service8: 'Rückentraining',
  service8Description:
    'Muskulatur stärken\n\nSchmerzen reduzieren\n\nHaltungsschwächen beseitigen\n\nVerspannungen lösen',
}

export default messages
