import styled from 'styled-components'

import Typography from '~components/Typography'

const Benefit = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  flex: 1;
`

export default Benefit
