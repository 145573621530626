import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const QuoteText = styled(Typography)`
  ${fluidSpacingFunc([{ property: 'padding', values: [3] }])};
  ${({ position }) => `padding-${position}: 0`};
`

export default QuoteText
