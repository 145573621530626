/**
 *
 * IconSuccess
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconWeighingScale = () => (
  <Svg viewBox="0 0 400.73 400.73">
    <path d="M123.35 182.52H12.5c-6.9 0-12.5 5.6-12.5 12.5 0 37.45 30.47 67.92 67.92 67.92s67.93-30.47 67.93-67.92c0-6.9-5.6-12.5-12.5-12.5zm-55.42 55.42a43 43 0 0 1-41.07-30.42h82.13a43 43 0 0 1-41.06 30.42zM388.23 182.52H277.38c-6.9 0-12.5 5.6-12.5 12.5a68 68 0 0 0 67.93 67.92 68 68 0 0 0 67.92-67.92c0-6.9-5.6-12.5-12.5-12.5zm-55.42 55.42a43 43 0 0 1-41.07-30.42h82.13a43 43 0 0 1-41.06 30.42z" />
    <path d="M241.61 336.75h-28.75V92.05c11.12-1.33 23.87-4.05 37.16-6.89 15.35-3.28 31.23-6.67 44.56-7.85 16.87-1.5 22.35 1.3 23.93 2.58.42.34 1.3 1.06 1.64 3.43l-23.75 79.1a12.5 12.5 0 0 0 23.95 7.2l12.43-41.44 12.44 41.43a12.5 12.5 0 0 0 23.94-7.18L345.17 82.5c-.8-11.46-6.28-18.28-10.94-22.05-18.44-14.91-52.92-7.54-89.43.26-11.52 2.47-22.6 4.83-31.93 6.14V51.48a12.5 12.5 0 0 0-25 0v15.37c-9.33-1.3-20.41-3.67-31.94-6.14-36.5-7.8-71-15.17-89.43-.26-4.66 3.77-10.14 10.6-10.94 22.05l-24 79.93a12.5 12.5 0 0 0 23.95 7.19l12.44-41.44 12.44 41.43a12.5 12.5 0 0 0 23.94-7.18L80.58 83.3c.34-2.36 1.22-3.08 1.64-3.42 1.58-1.28 7.06-4.08 23.93-2.59 13.33 1.19 29.2 4.58 44.56 7.86 13.29 2.84 26.04 5.56 37.15 6.89v244.7h-28.74a12.5 12.5 0 1 0 0 25h82.5a12.5 12.5 0 0 0 0-25z" />
  </Svg>
)

export default IconWeighingScale
