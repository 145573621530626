import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const ServiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: ${({ position }) => `service-${position}`};
  background: ${({ theme }) => theme.palette.primary.main};
  ${media.mq1`
    padding: ${({ theme }) => theme.spacing[12]};
  `};
  ${media.mq234`
    padding: ${({ theme }) => theme.spacing[24]};
  `};
`

export default ServiceWrapper
