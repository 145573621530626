import styled from 'styled-components'

import { icon } from '~styles/constants'

const Svg = styled.svg`
  fill: ${({ theme }) => theme.palette.secondary.main};
  width: ${icon.size.xsmall}px;
  height: ${icon.size.xsmall}px;
  margin-right: ${({ theme }) => theme.spacing[8]};
`

export default Svg
